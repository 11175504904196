import React, { useState, useEffect } from "react"
import Table from "react-bootstrap/Table"
import { useTable } from "react-table"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined"

export default function TableComponent({
	columns,
	data,
	pageIndex,
	pageLimit,
}) {
	const [open, setOpen] = useState([])
	const handleTooltipStatus = (index) => {
		const newOpen = [...open]
		newOpen[index] = !open[index]
		setOpen(newOpen)
	}
	const tdStyle = {
		padding: "12px",
		color: "#051D22",
		fontWeight: "normal",
		borderWidth: "0px",
	}
	const thStyle = {
		textAlign: "left",
		fontFamily: "Open Sans",
		fontSize: "16px",
		fontWeight: "600",
		color: "#fff",
		backgroundColor: "#00586f",
		padding: "12px",
		borderWidth: 0,
		height: "38px",
	}

	useEffect(() => {
		setOpen(new Array(data.length).fill(false))
	}, [data.length])

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "#f5f5f9",
			color: "rgba(0, 0, 0, 0.87)",
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: "1px solid #dadde9",
		},
	}))

	const downloadCsv = (blobName, blobContainer) => {
		blobName = blobName.substr(0, blobName.lastIndexOf(".")) + ".csv"
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				blobName: blobName,
				blobContainer: blobContainer,
			}),
		}

		fetch(
			process.env.REACT_APP_INTERNAL_API_URL + "blob/getBlobWithContent",
			requestOptions
		)
			.then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", `FileName.csv`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}

	console.log({ columns, data })

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data: data || [],
		})
	return (
		<div>
			<Table {...getTableProps()} style={{ marginBottom: "0px" }} hover>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							<th style={{ ...thStyle, width: "41.3px" }}>#</th>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps()}
									style={{ ...thStyle, width: column.width }}
								>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody
					{...getTableBodyProps()}
					style={{
						textAlign: "left",
						fontFamily: "Open sans",
						fontSize: "16px",
						fontWeight: "normal",
						fontStyle: "normal",
						color: "#051D22",
						borderTop: "0px",
					}}
				>
					{rows.map((row, i) => {
						prepareRow(row)
						return (
							<tr
								style={
									i % 2 === 0
										? { backgroundColor: "#F2F7F8" }
										: { backgroundColor: "#FFF" }
								}
								{...row.getRowProps()}
							>
								<td style={{ ...tdStyle, paddingLeft: "8px" }}>
									{(pageIndex - 1) * pageLimit + i + 1}
								</td>
								{row.cells.map((cell) => {
									if (
										cell.column.id === "rows_rejected" &&
										typeof cell.value === "number" &&
										cell.value > 0
									)
										return (
											<td style={tdStyle} {...cell.getCellProps()}>
												<button
													style={{
														border: "none",
														color: "#004C9D",
														textDecoration: "underline",
														cursor: "pointer",
														backgroundColor: "transparent",
														padding: "0px",
													}}
													onClick={() => {
														downloadCsv(
															row.original.blobName,
															"rejected"
														)
													}}
												>
													{cell.render("Cell")}
												</button>
											</td>
										)
									else if (cell.column.id === "blobName") {
										const filename = cell.value
										return (
											<td style={tdStyle} {...cell.getCellProps()}>
												{filename.substr(
													filename.indexOf("_") + 1,
													filename.length
												)}
											</td>
										)
									} else if (cell.column.id === "message")
										if (cell.value) {
											return (
												<td
													style={tdStyle}
													{...cell.getCellProps()}
												>
													<HtmlTooltip
														PopperProps={{
															disablePortal: true,
														}}
														open={open[i]}
														disableFocusListener
														disableHoverListener
														disableTouchListener
														title={cell.value}
													>
														<IconButton
															onClick={() =>
																handleTooltipStatus(i)
															}
															aria-label="open error messsage tooltip"
															style={{
																padding: "0px",
															}}
														>
															<ErrorOutlineOutlinedIcon color="action" />
														</IconButton>
													</HtmlTooltip>
												</td>
											)
										} else {
											return (
												<td
													style={tdStyle}
													{...cell.getCellProps()}
												>
													-
												</td>
											)
										}
									else if (cell.column.id === "status") {
										switch (cell.value) {
											case "Succeeded":
												return (
													<td
														style={tdStyle}
														{...cell.getCellProps()}
													>
														{
															<CheckCircleIcon
																style={{
																	color: "#00B7BD",
																	marginRight: "2px",
																}}
															/>
														}
														{cell.render("Cell")}
													</td>
												)
											case "Failed":
												return (
													<td
														style={tdStyle}
														{...cell.getCellProps()}
													>
														{
															<CancelIcon
																sx={{
																	color: "#D63964",
																	marginRight: "2px",
																}}
															/>
														}
														{cell.render("Cell")}
													</td>
												)
											case "InProgress":
												return (
													<td
														style={tdStyle}
														{...cell.getCellProps()}
													>
														{
															<ArrowCircleUpOutlinedIcon
																sx={{
																	color: "#004C9DCC",
																	marginRight: "2px",
																}}
															/>
														}
														{cell.render("Cell")}
													</td>
												)
											default:
												return (
													<td
														style={tdStyle}
														{...cell.getCellProps()}
													>
														{cell.render("Cell")}
													</td>
												)
										}
									} else if (
										cell.column.id === "run_start" ||
										cell.column.id === "run_end"
									) {
										const pipeTime = cell.value
										return (
											<td style={tdStyle} {...cell.getCellProps()}>
												{pipeTime === undefined
													? ""
													: new Date(pipeTime).toLocaleString(
															"en-GB",
															{
																hour12: false,
															}
													  )}
											</td>
										)
									} else
										return (
											<td style={tdStyle} {...cell.getCellProps()}>
												{cell.render("Cell")}
											</td>
										)
								})}
							</tr>
						)
					})}
				</tbody>
			</Table>
		</div>
	)
}
