import React, { useState, useEffect, useMemo } from "react"
import axios from "axios"
import TableComponent from "./table"
import { useInterval } from "usehooks-ts"
import styles from "./FileUploadOverview.module.css"
import IconButton from "@mui/material/IconButton"
import { ReactComponent as LeftSvg } from "../styles/images/Left.svg"
import { ReactComponent as RightSvg } from "../styles/images/Right.svg"
import { ReactComponent as LeftDisableSvg } from "../styles/images/LeftDisable.svg"
import { ReactComponent as RightDisableSvg } from "../styles/images/RightDisable.svg"
import { ReactComponent as RotatingSvg } from "../styles/images/RotatingIcon.svg"
import { isInDesiredForm } from "../utils"

export default function FileUploadOverview(props) {
	axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
	//axios.defaults.headers.post["Access-Token"] = "Bearer " + props.accessToken;
	//axios.defaults.headers.get["Access-Token"] = "Bearer " + props.accessToken;
	axios.defaults.xsrfHeaderName = "X-CSRFToken"
	axios.defaults.xsrfCookieName = "csrftoken"
	const [loadingData, setLoadingData] = useState(true)
	const [requestingData, setRequestingData] = useState(false)

	const [page, setPage] = useState(1)
	const [totalUploads, setTotalUploads] = useState(1)
	const [maxPage, setMaxPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(8)

	const [pauseAutoRefreshing, setPauseAutoRefreshing] = useState(false)
	const [timeoutId, setTimeoutId] = useState()

	const handlePageChange = (p) => {
		setPage(p)
		setData([])
		setRequestingData(true)
		getData(pageLimit, p)
		startPauseAutoRefreshing()
	}

	const handlePageLimitChange = (e) => {
		const pageLimit = e.target.value
		if (isInDesiredForm(pageLimit)) {
			const pl = parseInt(pageLimit) > 25 ? 25 : pageLimit
			setPageLimit(pl)
		}
	}

	const handleOnBlur = (e) => {
		const currentMaxPage = Math.ceil(totalUploads / pageLimit)
		if (currentMaxPage !== maxPage) {
			setMaxPage(Math.ceil(totalUploads / pageLimit))
			setData([])
			getData(pageLimit, page)
			startPauseAutoRefreshing()
			setRequestingData(true)
		}
	}

	const columns = useMemo(
		() => [
			{
				Header: "Filnavn",
				accessor: "blobName",
				width: 303,
			},
			{
				Header: "Jobb Status",
				accessor: "status",
				width: 172,
			},
			{
				Header: "Jobb Start",
				accessor: "run_start",
				width: 172,
			},
			{
				Header: "Jobb Slutt",
				accessor: "run_end",
				width: 172,
			},
			// {
			//   Header: "Prosessering tid(ms)",
			//   accessor: "duration_in_ms",
			// },
			{
				Header: "Rader Filtrert ut",
				accessor: "rows_rejected",
				width: 172,
			},
			{
				Header: "Rader Godkjent",
				accessor: "rows_validated",
				width: 172,
			},
			{
				Header: "Feilmelding",
				accessor: "message",
				width: 172,
			},
		],
		[]
	)

	const [data, setData] = useState([])

	const startPauseAutoRefreshing = (second) => {
		setPauseAutoRefreshing(true)
		clearTimeout(timeoutId)
		const id = setTimeout(() => {
			setPauseAutoRefreshing(false)
		}, 60000)
		setTimeoutId(id)
	}

	async function getTotalUploadsNumber() {
		const totalUploadReponse = await axios.get(
			process.env.REACT_APP_INTERNAL_API_URL +
				`blob/numberOfUploads?companyCode=${props.companyCode}`
		)

		if ((totalUploadReponse.status = 200)) {
			const tur = totalUploadReponse.data
			setTotalUploads(tur)
			setMaxPage(Math.ceil(tur / pageLimit))
		}
	}
	async function getData(pageItemsLength, pageIndex) {
		if (props.companyCode === "") {
			return
		}

		const blobResponse = await axios.get(
			process.env.REACT_APP_INTERNAL_API_URL +
				`blob/getLatestUploads?listLength=${pageItemsLength}&&companyCode=${props.companyCode}&&page=${pageIndex}`
		)
		// const blobResponseData = blobResponse.data.map(data=>{
		//   const o ={}
		//   delete Object.assign(o,data, {"location": data["status"] })["status"];
		//   return o
		// })
		const blobResponseData = blobResponse.data
		if (blobResponseData.length === 0) {
			return
		}

		//function checking that only fetch status of the files that don't existed in current 'data' or has status of 'InProgress'
		const filesExistingOrInprogress = (fileName) => {
			const existingFiles = data.filter((d) => d.blobName === fileName)

			// file doesn't exist in current data list
			if (existingFiles.length === 0) {
				return true
			}

			// file exists but its status is in progress
			if (existingFiles[0].status === "InProgress") {
				return true
			}

			// file exists and its status is not in progess
			return false
		}

		const statusPostBody = {
			file_names: blobResponseData
				.map((d) => {
					const blobName = d.blobName
					if (filesExistingOrInprogress(blobName)) {
						return d.blobName
					}
					return null
				})
				.filter((d) => d !== null),
		}
		if (statusPostBody.file_names.length === 0) {
			return
		}
		const statusResponse = await axios.post(
			`${process.env.REACT_APP_INTERNAL_API_URL}filestatus`,
			statusPostBody
		)
		const statusData = statusResponse.data
		var concactedResponseData = blobResponseData.map((brd) => {
			const blobName = brd.blobName
			if (statusData[blobName]) {
				console.log(`Found item ${blobName}`, statusData[blobName])
				const concactedData = {
					...brd,
					...statusData[blobName],
				}
				return concactedData
			}

			console.log(`Did not find item ${blobName}, returning null`)
			return null
		})
		const filteredData = concactedResponseData.filter((item) => Boolean(item))
		console.log({ concactedResponseData, filteredData })
		setData(filteredData)
		setRequestingData(false)
		setLoadingData(false)
	}

	useInterval(() => {
		if (!loadingData && !pauseAutoRefreshing) getData(pageLimit, page)
	}, 10000)

	useEffect(() => {
		if (loadingData) {
			getTotalUploadsNumber()
			getData(pageLimit, page)
		}
	}, [props.companyCode]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div
			className={styles["table-container"]}
			style={{ display: !loadingData ? "flex" : "none" }}
		>
			<span className={styles["table-title"]}>Filer</span>
			<TableComponent
				columns={columns}
				data={data}
				pageIndex={page}
				pageLimit={pageLimit}
			/>
			<div
				className={styles["requesting-data"]}
				style={{ display: requestingData ? "flex" : "none" }}
			>
				<div className={styles["requesting-data-content"]}>
					<RotatingSvg style={{ marginBottom: "12px" }} />
					Laster inn..
				</div>
			</div>
			<div className={styles["table-pagination"]}>
				<div className={styles["pagination-placeholder"]}></div>
				<div className={styles["pagination-center"]}>
					<IconButton
						style={{
							padding: "10px",
						}}
						disabled={page === 1 ? true : false}
						onClick={() => handlePageChange(page - 1)}
					>
						{page !== 1 ? <LeftSvg /> : <LeftDisableSvg />}
					</IconButton>
					<textarea className={styles["textarea"]} value={page} readOnly />
					<IconButton
						style={{
							padding: "10px",
						}}
						disabled={page === maxPage ? true : false}
						onClick={() => handlePageChange(page + 1)}
					>
						{page !== maxPage ? <RightSvg /> : <RightDisableSvg />}
					</IconButton>
					<span>av {maxPage} sider</span>
				</div>
				<div className={styles["pagination-end"]}>
					<span>Vis</span>
					<textarea
						rows="1"
						className={styles["textarea"]}
						style={{
							margin: "0px 8px",
						}}
						value={pageLimit}
						onChange={handlePageLimitChange}
						onBlur={handleOnBlur}
					/>
					<span>elementer per side</span>
				</div>
			</div>
		</div>
	)
}
